import { Theme } from "@mui/material/styles";
import { TextFieldProps } from "@mui/material/TextField";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ERROR_COLOR } from "ds_legacy/materials/colors";
import { border, dp, padding } from "ds_legacy/materials/metrics";
import { SelectType } from "./types";

export const useStyles = ({
  fontSize,
  hasError,
  isSearchable,
}: {
  fontSize: string;
  hasError: boolean;
  isSearchable: boolean | undefined;
}) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        marginTop: 0,
        "& label + .MuiInputBase-root": {
          marginTop: dp(8),
        },
      },
      input: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "auto",
        padding: 0,
        cursor: isSearchable ? "text" : "pointer",
      },
      noOptionsMessage: {
        padding: theme.spacing(1, 2),
      },
      inputError: {
        borderBottom: border({ width: 2, color: ERROR_COLOR }),
      },
      singleValue: {
        fontSize,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        position: "absolute",
        maxWidth: `calc(100% - ${dp(40)})`,
        color: hasError ? ERROR_COLOR : undefined,
      },
      paper: {
        position: "relative",
        overflow: "initial",
        zIndex: 10000,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
      },
      divider: {
        height: theme.spacing(2),
      },
      valueContainerSingle: {
        fontSize,
        display: "flex",
        alignItems: "center",
        maxWidth: `calc(100% - ${dp(40)})`,
        cursor: "pointer",
        padding: padding(1, 0, 0, 0),
      },
      valueContainerSingleItem: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      },
      valueContainerMulti: {
        display: "inline-flex",
        flexWrap: "wrap",
        alignItems: "center",
        width: `calc(100% - ${dp(44)})`,
        padding: padding(1.25, 0, 0.25),
        minHeight: dp(48),
      },
      valueContainerMultiItems: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        maxWidth: "100%",

        "&> div": {
          margin: dp(2),
        },

        "&> div:last-child": {
          flexGrow: 0,
          minHeight: dp(32),
        },
      },
      valueContainerMultiEllipsis: {
        fontSize,
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: "inline-block",
        textOverflow: "ellipsis",
      },
      dropdownIndicatorContainer: {
        padding: padding(1, 0, 0, 0.5),
        cursor: "pointer",
      },
      dropdownIndicatorContainerFilter: {
        position: "relative",
        top: "-4px",
      },
      menuItem: {
        display: "block",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "100%",
        padding: padding(1, 2),
        outlineOffset: dp(-2),
        "&.Mui-selected": {
          outline: `${dp(1)} solid ${theme.palette.primary.dark}`,
        },
      },
      placeholder: {
        fontSize,
        color: hasError ? ERROR_COLOR : undefined,
      },
    }),
  );

export function isValueEmpty(selectValue: any, multiple: boolean) {
  if (!selectValue) return true;

  // is multi select
  if (multiple) {
    return (
      (Array.isArray(selectValue) && selectValue.length === 0) ||
      (Array.isArray(selectValue.value) && selectValue.value.length === 0)
    );
  }

  // is single select
  if (selectValue instanceof Object && !selectValue.label) {
    return true;
  }
  return typeof selectValue === "string" && selectValue.length === 0;
}

export function getTextFieldStyles({
  fontSize,
  hasError,
}: {
  fontSize: string;
  hasError?: boolean;
}) {
  let textFieldStyles = {
    fontSize,
    padding: padding(0, 0, 1),
    transform: `translate(0, ${dp(4)}) scale(0.8)`,
  };
  if (hasError) {
    textFieldStyles = Object.assign(textFieldStyles, { color: ERROR_COLOR });
  }
  return textFieldStyles;
}

export function getTextFieldProps({
  customNoResult,
  elementName,
  label,
  onBlur,
  onFocus,
  onSearch,
  placeholder,
  required,
  setSearchInputValue,
  testId,
  textFieldStyles,
}: Partial<SelectType> & {
  empty: boolean;
  setSearchInputValue: React.Dispatch<React.SetStateAction<string>>;
  textFieldStyles: ReturnType<typeof getTextFieldStyles>;
}) {
  const handleFocus: TextFieldProps["onFocus"] = (e) => {
    onFocus?.(e);
  };
  const handleBlur: TextFieldProps["onBlur"] = (e) => {
    onBlur?.(e);
  };
  const handleChange: TextFieldProps["onChange"] = (e) => {
    if (customNoResult) {
      setSearchInputValue(e.target?.value);
    }
    onSearch?.(e);
  };

  return {
    label: label ? `${label}${required ? " *" : ""}` : "",
    InputLabelProps: {
      style: textFieldStyles,
    },
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    placeholder,
    "data-testid": testId ?? elementName,
  };
}
