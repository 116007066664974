import {
  EARLY_REHAB_CRITERIA,
  MUSCOSKELETAL_RESILIENCE,
  PERFORMED_TREATMENT,
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_YES,
  REHAB_ADMISSION,
  REHAB_FORM_NAMES,
  TYPE_OF_REHAB,
  YES_NO_FIELD_OPTIONS,
} from "core/consts";
import { EarlyRehabBarthelCriteria, TypeOfRehab } from "core/types";
import {
  ICD_CODE_CHARACTER_LIMIT,
  INFECTIONS_CHARACTER_LIMIT,
  OldRehabFormDataMapped,
  booleanToPredicamentState,
  convertActivableInputToBoolean,
  stringDateToUnix,
  yesNoUnknownToPredicament,
} from "../../utils";
import { getTransportMapping } from "./importAuction";
import {
  MedicalFormUniversalData,
  MedicalFormUniversalDataOld,
} from "./modelDefinition";

export const convertMedicalFormUniversalData = ({
  formDataOld,
}: {
  formDataOld: OldRehabFormDataMapped<
    typeof REHAB_FORM_NAMES.MEDICAL_UNIVERSAL
  >;
}): MedicalFormUniversalData => {
  if (!formDataOld) return {};
  const newData: MedicalFormUniversalData = {};

  Object.keys(formDataOld).forEach((key) => {
    switch (key as keyof MedicalFormUniversalDataOld) {
      case "admission_date": {
        newData.admission_date = formDataOld.admission_date || null;
        break;
      }
      case "complication_in_treatment_process": {
        newData.complication_cause_maindiagnosis =
          convertActivableInputToBoolean(
            formDataOld.complication_in_treatment_process
              ?.complication_cause_maindiagnosis ?? null,
          );

        newData.complication_cause_maindiagnosis_description =
          formDataOld.complication_in_treatment_process
            ?.complication_cause_maindiagnosis || null;

        newData.complication_cause_cardiovascular =
          convertActivableInputToBoolean(
            formDataOld.complication_in_treatment_process
              ?.complication_cause_cardiovascular,
          );

        newData.complication_cause_cardiovascular_description =
          formDataOld.complication_in_treatment_process
            ?.complication_cause_cardiovascular || null;

        newData.complication_cause_other = convertActivableInputToBoolean(
          formDataOld.complication_in_treatment_process
            ?.complication_cause_other,
        );

        newData.complication_cause_other_description =
          formDataOld.complication_in_treatment_process
            ?.complication_cause_other || null;
        break;
      }

      case "operations":
      case "operation_date":
      case "current_therapy":
      case "operation_description": {
        if (formDataOld.current_therapy) {
          newData.performed_treatment = formDataOld.current_therapy
            ? PERFORMED_TREATMENT.OTHER
            : null;
          newData.current_therapy = formDataOld.current_therapy || null;
        }

        const operationCondition =
          formDataOld.operations ||
          formDataOld.operation_date ||
          formDataOld.operation_description ||
          formDataOld.wound_closed;

        if (operationCondition) {
          newData.performed_treatment = operationCondition
            ? PERFORMED_TREATMENT.SURGERY
            : null;
          newData.operations = formDataOld.operations || null;
          newData.operation_date = formDataOld.operation_date || null;
          newData.operation_description =
            formDataOld.operation_description || null;
        }
        break;
      }

      case "direct_transfer_necessary": {
        newData.rehab_admission_type = formDataOld.direct_transfer_necessary
          ? REHAB_ADMISSION.DIRECT
          : null;
        break;
      }

      case "discharge_date": {
        newData.discharge_date = formDataOld.discharge_date || null;
        break;
      }

      case "doctor_in_charge_in_hospital": {
        newData.doctor_in_charge_in_hospital =
          formDataOld.doctor_in_charge_in_hospital || null;
        break;
      }

      case "doctor_in_charge_in_hospital_phone": {
        newData.doctor_in_charge_in_hospital_phone =
          formDataOld.doctor_in_charge_in_hospital_phone || null;
        break;
      }

      case "early_rehabilitation_measures": {
        newData.early_rehabilitation_measures = yesNoUnknownToPredicament(
          formDataOld.early_rehabilitation_measures,
        );
        break;
      }

      case "gender": {
        newData.gender = formDataOld.gender || null;
        break;
      }

      case "icd_code_1_code": {
        newData.icd_code_1_code =
          formDataOld.icd_code_1_code?.slice(0, ICD_CODE_CHARACTER_LIMIT) ||
          null;
        break;
      }
      case "icd_code_1_diagnosis": {
        newData.icd_code_1_diagnosis = formDataOld.icd_code_1_diagnosis || null;
        break;
      }
      case "icd_code_1_reason": {
        newData.icd_code_1_reason =
          formDataOld.icd_code_1_reason?.value.toString() || null;
        break;
      }

      case "icd_code_2_code": {
        newData.icd_code_2_code =
          formDataOld.icd_code_2_code?.slice(0, ICD_CODE_CHARACTER_LIMIT) ||
          null;
        break;
      }
      case "icd_code_2_diagnosis": {
        newData.icd_code_2_diagnosis = formDataOld.icd_code_2_diagnosis || null;
        break;
      }
      case "icd_code_2_reason": {
        newData.icd_code_2_reason =
          formDataOld.icd_code_2_reason?.value.toString() || null;
        break;
      }

      case "icd_code_3_code": {
        newData.icd_code_3_code =
          formDataOld.icd_code_3_code?.slice(0, ICD_CODE_CHARACTER_LIMIT) ||
          null;
        break;
      }
      case "icd_code_3_diagnosis": {
        newData.icd_code_3_diagnosis = formDataOld.icd_code_3_diagnosis || null;
        break;
      }
      case "icd_code_3_reason": {
        newData.icd_code_3_reason =
          formDataOld.icd_code_3_reason?.value.toString() || null;
        break;
      }

      case "icd_code_4_code": {
        newData.icd_code_4_code =
          formDataOld.icd_code_4_code?.slice(0, ICD_CODE_CHARACTER_LIMIT) ||
          null;
        break;
      }
      case "icd_code_4_diagnosis": {
        newData.icd_code_4_diagnosis = formDataOld.icd_code_4_diagnosis || null;
        break;
      }
      case "icd_code_4_reason": {
        newData.icd_code_4_reason =
          formDataOld.icd_code_4_reason?.value.toString() || null;
        break;
      }

      case "icd_code_5_code": {
        newData.icd_code_5_code =
          formDataOld.icd_code_5_code?.slice(0, ICD_CODE_CHARACTER_LIMIT) ||
          null;
        break;
      }
      case "icd_code_5_diagnosis": {
        newData.icd_code_5_diagnosis = formDataOld.icd_code_5_diagnosis || null;
        break;
      }
      case "icd_code_5_reason": {
        newData.icd_code_5_reason =
          formDataOld.icd_code_5_reason?.value.toString() || null;
        break;
      }

      case "insurance_number": {
        newData.insurance_number = formDataOld.insurance_number || null;
        break;
      }

      case "infections": {
        if (formDataOld.infections === 0) {
          newData.infections = null;
          if (
            formDataOld._complication_in_treatment_process ===
            YES_NO_FIELD_OPTIONS.YES
          )
            newData.infections_state = PREDICAMENT_STATE_NO;
          break;
        }
        if (typeof formDataOld.infections === "string") {
          newData.infections =
            formDataOld.infections?.slice(0, INFECTIONS_CHARACTER_LIMIT) ||
            null;
          newData.infections_state = PREDICAMENT_STATE_YES;
          break;
        }
        if (!formDataOld.infections) {
          newData.infections = null;
          newData.infections_state = null;
        }

        break;
      }

      case "interim_prosthesis_fitted": {
        newData.interim_prosthesis_fitted = yesNoUnknownToPredicament(
          formDataOld.interim_prosthesis_fitted,
        );
        break;
      }

      case "limited_capacity": {
        newData.limited_capacity = yesNoUnknownToPredicament(
          formDataOld.limited_capacity,
        );
        break;
      }

      case "patient_last_name": {
        newData.patient_last_name = formDataOld.patient_last_name || null;
        break;
      }

      case "patient_first_name": {
        newData.patient_first_name = formDataOld.patient_first_name || null;
        break;
      }

      case "patient_birthdate": {
        newData.patient_birthdate = stringDateToUnix({
          date: formDataOld.patient_birthdate,
        });
        break;
      }

      case "reha_capacity_full":
      case "reha_capacity_partial":
      case "stable_during_exercise": {
        if (newData.reha_capacity != null) return;

        const filledFields = [
          formDataOld.reha_capacity_full
            ? MUSCOSKELETAL_RESILIENCE.FULLY
            : null,
          convertActivableInputToBoolean(formDataOld.reha_capacity_partial)
            ? MUSCOSKELETAL_RESILIENCE.PARTIALLY
            : null,
          formDataOld.stable_during_exercise
            ? MUSCOSKELETAL_RESILIENCE.STABLE
            : null,
        ].filter((field) => field !== null);

        if (filledFields.length === 1) {
          newData.reha_capacity = filledFields[0];
        } else {
          newData.reha_capacity = null;
        }

        newData.reha_capacity_partial =
          formDataOld.reha_capacity_partial || null;

        break;
      }

      case "reha_capacity_other": {
        newData.reha_capacity_other = formDataOld.reha_capacity_other || null;
        break;
      }

      case "reha_clinic_info": {
        if (!formDataOld.reha_clinic_info) {
          newData.special_requirements_required = yesNoUnknownToPredicament(
            formDataOld._reha_clinic_info,
          );
          break;
        }
        newData.special_requirements_required = PREDICAMENT_STATE_YES;

        newData.special_requirements_catheter =
          !!formDataOld.reha_clinic_info.needs_urinary_catheter;

        newData.special_requirements_chemotherapy =
          !!formDataOld.reha_clinic_info.requirement_chemotherapy;

        newData.special_requirements_dialysis = convertActivableInputToBoolean(
          formDataOld.reha_clinic_info.needs_dialysis,
        );

        newData.special_requirements_dialysis_description =
          formDataOld.reha_clinic_info.needs_dialysis || null;

        newData.special_requirements_immunosuppressants =
          !!formDataOld.reha_clinic_info.requirement_immunosuppressants;

        newData.special_requirements_impairment =
          !!formDataOld.reha_clinic_info.requirement_severe_sense_impairment;

        newData.special_requirements_isolation =
          !!formDataOld.reha_clinic_info.requires_isolation;

        newData.special_requirements_obesity =
          !!formDataOld.reha_clinic_info.needs_obesity;

        newData.special_requirements_other = convertActivableInputToBoolean(
          formDataOld.reha_clinic_info.requirement_other,
        );

        newData.special_requirements_other_description =
          formDataOld.reha_clinic_info.requirement_other || null;

        newData.special_requirements_overlength_bed =
          !!formDataOld.reha_clinic_info.requirement_bed_overlength;

        newData.special_requirements_peg =
          !!formDataOld.reha_clinic_info.needs_peg_tube;

        newData.special_requirements_prosthesis =
          !!formDataOld.reha_clinic_info.requirement_leg_prothesis;

        newData.special_requirements_relatives =
          !!formDataOld.reha_clinic_info.requirement_relative_care;

        newData.special_requirements_tracheostoma =
          !!formDataOld.reha_clinic_info.needs_tracheostomy;

        newData.special_requirements_ventilation =
          !!formDataOld.reha_clinic_info.requirement_artificial_respiration;

        newData.special_requirements_weaning =
          !!formDataOld.reha_clinic_info.requirement_weaning;

        // decubitus and weight bed missing in old form
        break;
      }

      case "recommended_reha_type_ambulant":
      case "recommended_reha_type_ambulant_mobil":
      case "recommended_reha_type_stationary": {
        if (newData.recommended_reha_type != null) break;

        const oldDataCheckboxes: Partial<MedicalFormUniversalDataOld> = {
          recommended_reha_type_ambulant:
            formDataOld.recommended_reha_type_ambulant,
          recommended_reha_type_ambulant_mobil:
            formDataOld.recommended_reha_type_ambulant_mobil,
          recommended_reha_type_stationary:
            formDataOld.recommended_reha_type_stationary,
        } as const;

        const selectedValues = Object.entries(oldDataCheckboxes).filter(
          ([, value]) => value === true || typeof value === "string",
        );

        // when converting checkboxes to radios
        // if there is more than one checkbox selected we return null for the radio group
        if (selectedValues.length !== 1) {
          newData.recommended_reha_type = null;
          break;
        }

        const [selectedKey] =
          selectedValues[0] as unknown as keyof typeof oldDataCheckboxes;

        const rehabTypeMapping: Record<string, TypeOfRehab> = {
          recommended_reha_type_ambulant: TYPE_OF_REHAB.MOBILE,
          recommended_reha_type_ambulant_mobil: TYPE_OF_REHAB.MOBILE_OUTPATIENT,
          recommended_reha_type_stationary: TYPE_OF_REHAB.STATIONARY,
        };

        newData.recommended_reha_type = rehabTypeMapping[selectedKey];

        if (newData.recommended_reha_type === TYPE_OF_REHAB.MOBILE_OUTPATIENT) {
          newData.recommended_reha_type_ambulant_mobil =
            formDataOld.recommended_reha_type_ambulant_mobil || null;
          newData.recommended_reha_type_stationary = null;
        } else if (newData.recommended_reha_type === TYPE_OF_REHAB.STATIONARY) {
          newData.recommended_reha_type_stationary =
            formDataOld.recommended_reha_type_stationary || null;
          newData.recommended_reha_type_ambulant_mobil = null;
        }

        break;
      }

      case "rehabilitation_goals": {
        newData.rehabilitation_goals = formDataOld.rehabilitation_goals || null;
        break;
      }

      case "require_accompanying_person": {
        newData.require_accompanying_person = yesNoUnknownToPredicament(
          formDataOld.require_accompanying_person,
        );
        break;
      }

      case "transport_type": {
        const transportType = formDataOld?.transport_type;

        newData.transport_type = getTransportMapping(transportType);
        break;
      }

      case "wound_closed": {
        newData.wound_closed = yesNoUnknownToPredicament(
          formDataOld.wound_closed,
        );
        break;
      }

      case "behavioural_disorder":
      case "intensive_medical_monitoring":
      case "intermittent_ventilation":
      case "orientation_disorder":
      case "severe_communication_disorder":
      case "supervised_swallowing_disorder":
      case "tracheostoma_requiring_aspiration": {
        // Mapping for reha_capacity_early_rehab_criteria
        const criteriaMapping: Record<string, EarlyRehabBarthelCriteria> = {
          behavioural_disorder: EARLY_REHAB_CRITERIA.BEHAVIOURAL_DISORDER,
          intensive_medical_monitoring:
            EARLY_REHAB_CRITERIA.INTENSIVE_CARE_MONITORING,
          intermittent_ventilation:
            EARLY_REHAB_CRITERIA.INTERMITTENT_VENTILATION,
          orientation_disorder: EARLY_REHAB_CRITERIA.ORIENTATION_DISORDER,
          severe_communication_disorder:
            EARLY_REHAB_CRITERIA.COMMUNICATION_DISORDER,
          supervised_swallowing_disorder:
            EARLY_REHAB_CRITERIA.SWALLOWING_DISORDER,
          tracheostoma_requiring_aspiration: EARLY_REHAB_CRITERIA.TRACHEOSTOMA,
        };

        newData.reha_capacity_early_rehab_criteria = Object.entries(
          criteriaMapping,
        )
          .filter(
            ([key]) =>
              formDataOld[key as keyof MedicalFormUniversalDataOld] != null,
          )
          .map(([, criteria]) => criteria);

        break;
      }

      // not needed fields
      case "bathing":
      case "barthel_index_list":
      case "dressing_and_undressing":
      case "early_rehab_barthel_index_list":
      case "fecal_incontinence":
      case "food":
      case "prefered_clinic_contacted":
      case "setting_up_and_relocating":
      case "stair_climbing":
      case "support_self_sufficiency":
      case "threats_areas_life":
      case "threats_communication":
      case "threats_community_life":
      case "threats_domestic_life":
      case "threats_general_tasks":
      case "threats_learning":
      case "threats_mobility":
      case "threats_relationships":
      case "threats_self_sufficiency":
      case "toilet_use":
      case "transfer_date":
      case "urinary_incontinence":
      case "_complication_in_treatment_process":
      case "_reha_clinic_info":
      case "no_direct_transfer_necessary_explanation":
      case "personal_care":
      case "reason_for_reha_clinic":
      case "retirement_earning_capacity_company":
      case "getting_up_and_walking": {
        break;
      }

      default:
        break;
    }
  });

  newData.complication_in_treatment_process = booleanToPredicamentState(
    newData.infections_state === PREDICAMENT_STATE_YES ||
      formDataOld._complication_in_treatment_process ===
        YES_NO_FIELD_OPTIONS.YES,
  );

  return newData;
};
