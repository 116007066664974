import { SingleValueProps } from "react-select";
import { SelectOption } from "./types";

export function SingleValue({
  children,
  innerProps,
  selectProps: { classes },
}: SingleValueProps<SelectOption> & { isFocused?: boolean }) {
  return (
    <p className={classes.singleValue} {...(innerProps as any)}>
      {children}
    </p>
  );
}
