import { DialogActions, DialogTitle, IconButton } from "@mui/material";
import {
  BarthelAssessment,
  DeepNullableObj,
  EarlyRehabAssessment,
  SingerAssessment,
} from "core/types";
import { CloseIcon } from "ds/icons";
import RSButton from "ds_legacy/components/RSButton";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_16,
  FONT_SIZE_20,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_SEMI_BOLD,
  LINE_HEIGHT_24,
} from "ds_legacy/materials/typography";
import { useCallback, useEffect, useState } from "react";
import { ObservableValue } from "react-forms-state/src/Context";
import { Observable } from "rxjs";
import { useTranslations } from "translations";

type ScoreForms =
  | BarthelAssessment["form"]
  | EarlyRehabAssessment["form"]
  | SingerAssessment["form"];

export function calculateScore(form?: ScoreForms): number {
  if (!form) return 0;

  return Object.values(form)
    .filter((value) => typeof value === "number")
    .map((value) => +value)
    .reduce((currentValue, newValue) => currentValue + newValue, 0);
}

export function useGetFormValues(
  valueChangeObs: Observable<ObservableValue> | undefined,
) {
  const [formValue, setFormValue] = useState<
    | SingerAssessment["form"]
    | EarlyRehabAssessment["form"]
    | BarthelAssessment["form"]
  >();

  useEffect(() => {
    if (!valueChangeObs) return;

    const obs = valueChangeObs.subscribe((e) => {
      // Create a new object to ensure immutability
      const form = { ...(e.value?.form ?? {}) };
      Object.keys(form).forEach((key: string) => {
        if (typeof form[key] === "string") {
          form[key] = +form[key];
        }
      });
      setFormValue(form);
    });

    return () => obs.unsubscribe();
  }, [valueChangeObs, setFormValue]);

  return formValue;
}

export function DialogHeader({
  onCancel,
  title,
}: {
  onCancel?: () => void;
  title: string;
}) {
  const translations = useTranslations();
  return (
    <div
      data-testid="independence-dialog-header"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <DialogTitle sx={{ fontWeight: FONT_WEIGHT_BOLD }}>{title}</DialogTitle>
      <IconButton
        data-testid="close-modal"
        size="medium"
        aria-label={translations.actions.close}
        onClick={onCancel}
        sx={{
          boxSizing: "border-box",
          height: dp(44),
          width: dp(44),
          margin: margin(1),
        }}
      >
        <CloseIcon style={{ fontSize: FONT_SIZE_16 }} size={FONT_SIZE_16} />
      </IconButton>
    </div>
  );
}

export function TotalScore({ score }: { score: number }) {
  const translations = useTranslations();

  return (
    <Body
      style={{ display: "flex", gap: padding(1) }}
      fontSize={FONT_SIZE_20}
      margin={0}
      lineHeight={LINE_HEIGHT_24}
      fontWeight={FONT_WEIGHT_SEMI_BOLD}
      as="p"
      data-testid="total-score"
    >
      <span>
        {
          translations.patient.medicalDiagnosis.singerPatientProfile
            .scoreTotalScore
        }
      </span>
      <span>{score}</span>
    </Body>
  );
}

export function AssessmentActions({
  defaultValues,
  onCancel,
  onChange,
  submit,
}: {
  defaultValues: DeepNullableObj<
    Omit<SingerAssessment | EarlyRehabAssessment | BarthelAssessment, "score">
  >;
  onCancel?: () => void;
  onChange?: (value: any, statePath: string, validation: any) => void;
  submit: () => void;
}) {
  const translations = useTranslations();

  const handleCancel = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  return (
    <DialogActions
      sx={{
        justifyContent: "space-between",
        padding: padding(0, 3, 2.5, 3),
      }}
    >
      <RSButton
        color="primary"
        id="reset"
        loading="na"
        onClick={() => onChange?.(defaultValues, "", true)}
        style={{ margin: 0 }}
        type="reset"
        variant="outlined"
      >
        {
          translations.patient.medicalDiagnosis.singerPatientProfile
            .buttonClearContent
        }
      </RSButton>
      <HorizontalLayout
        gap={padding(1)}
        overflow="visible"
        boxSizing="border-box"
      >
        <RSButton
          color="primary"
          id="cancel"
          loading="na"
          onClick={handleCancel}
          style={{ margin: 0 }}
          variant="outlined"
        >
          {translations.actions.cancel}
        </RSButton>
        <RSButton
          color="primary"
          id="submit"
          loading="na"
          onClick={submit}
          style={{ margin: 0 }}
          type="submit"
          variant="contained"
        >
          {translations.actions.save}
        </RSButton>
      </HorizontalLayout>
    </DialogActions>
  );
}
